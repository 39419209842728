<template>
  <!-- eslint-disable max-len -->
<div class="fluid-container" >
  <b-navbar toggleable="lg" type="dark" variant="dark">
    <b-navbar-brand href="#" to="/">Taurix Status</b-navbar-brand>

  </b-navbar>
<div class="mt-3">
<Status/>
</div>

</div>
</template>

<script>
import Status from './components/Status.vue'

export default {
  name: 'App',
  components: {
    Status
  },
}
</script>
