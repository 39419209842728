<template>
  <div class="container">
    <div><h2>Active incidents</h2></div>
    <hr/>
    <div v-if="loaded && incidents.length > 0">
      <div v-for="(incident, index) in incidents" :key="index">
        <div v-if="incident.status == 'open'">
        <b-alert variant="danger" show >
          [{{ incident.datetime }}] <a :href="'https://support.taurix.net/issues/' + incident.ticket">{{ incident.title }}</a>
          <div>{{ incident.description }}</div>
        </b-alert>
        </div>
      </div>

      <div><h2>Resolved incidents</h2></div>
      <hr/>
      <div v-for="(incident, index) in incidents" :key="index">
        <div v-if="incident.status == 'closed'">
        <b-alert variant="success" show >
          [{{ incident.datetime }}] <a :href="'https://support.taurix.net/issues/' + incident.ticket">{{ incident.title }}</a>
          <div>{{ incident.description }}</div>
        </b-alert>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Status',
  data() {
    return {
      loaded: false,
      status: null,
      incidents: null,
      raw: null,
    };
  },
  methods: {
    getIncidents() {
      const path = '/data/incidents.json';
      axios.get(path)
        .then((res) => {
          this.raw = res;
          this.status = res.data.status;
          this.incidents = res.data.incidents;
          this.loaded = true;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
  },
  created() {
    this.getIncidents();
    setInterval(() => {
      this.getIncidents();
    }, 30000);
  },
}
</script>
